import React from "react";
import { HashLink } from "react-router-hash-link";

const NavLinks = () => {
  return (
    <>
      <section className="body-font font-publicSans">
        <HashLink
          className="px-2 font-extrabold text-primary hover:text-blue-900"
          smooth
          to="/#about"
        >
          About
        </HashLink>
        <HashLink
          className="px-2 font-extrabold text-primary hover:text-blue-900"
          smooth
          to="/#services"
        >
          Services
        </HashLink>
        <HashLink
          className="px-2 font-extrabold text-primary hover:text-blue-900"
          to="/"
        >
          Portfolio
        </HashLink>
        <HashLink
          className="px-2 font-extrabold text-primary hover:text-blue-900"
          to="/contact"
        >
          Contact Us
        </HashLink>
      </section>
    </>
  );
};

export default NavLinks;
