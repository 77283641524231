import React from "react";
import cosalock from "../images/cosadevlock.png";
import cosaunlock from "../images/cosalockv2.png";
import cosaoverall from "../images/cosalockv3.png";

const clientImage = {
  height: "40rem",
  width: "auto",
  mixBlendMode: "colorBurn",
};

const Clients = () => {
  return (
    <section className="m-auto max-w-6xl p-2 md:p-12 h-5/6 body-font font-publicSans">
      <section data-aos="fade-up">
        <div className="my-3 py-4 mx-12">
          <h2 className="my-4 text-center text-3xl text-blue-900 uppercase font-bold text-primary ">
            COSA unlocks Dev Ops.
          </h2>
        </div>

        <div class="">
          <table class="border-separate border-spacing-0 border-CosaGreen border-4 w-full sm:w-auto">
            <thead>
              <tr>
                <th class="border border-t-CosaGreen border-x-CosaGreen border-r-4 sm:w-1/2">
                  <h2 className="mt-4 text-center text-xl lg:text-2xl font-semibold text-blue-900 text-primary">
                    TRADITIONAL APPROACH
                  </h2>
                </th>
                <th class="border border-t-CosaGreen border-x-CosaGreen border-l-4 sm:w-auto">
                  <h2 className="mt-4 text-center text-xl lg:text-xl font-semibold text-blue-900 text-primary">
                    COSA APPROACH
                  </h2>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border  border-x-CosaGreen border-r-4">
                  <div class="flex text-center text-primary text-2xl sm:w-auto">
                    {" "}
                    Any new feature is thoroughly vetted in an extensive and
                    time-consuming process before being approved for deployment.
                  </div>
                </td>
                <td class="border  border-x-CosaGreen s border-l-4">
                  <div class="flex text-center text-primary text-2xl sm:w-auto">
                    Any new feature will always be ready for release, as a full
                    safety case is automatically built in the CI/CD machinery.
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td class="border  border-b-CosaGreen border-x-CosaGreen border-r-4">
                  <div className="p-4" data-aos="fade-in" data-aos-delay="600">
                    <div className="flex justify-center items-center">
                      <div className=" overflow-hidden flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                        <img
                          src={cosalock}
                          alt="client"
                          className="w-full h-full object-cover"
                        />
                      </div>
                    </div>
                  </div>
                </td>
                <td class="border border-b-CosaGreen border-x-CosaGreen border-l-4">
                  <div className="p-4" data-aos="fade-in" data-aos-delay="600">
                    <div className="flex justify-center items-center">
                      <div className="overflow-hidden flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100 ">
                        <img
                          src={cosaunlock}
                          alt="client"
                          className="w-full h-full object-cover"
                        />
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </section>
  );
};

export default Clients;
