import React from "react";
import img from "../images/Web-developer.png";
import { Link } from "react-router-dom";

const Intro = () => {
  return (
    <>
      <section className="text-gray-600 body-font font-publicSans">
        <div
          className="m-auto max-w-6xl p-2 md:p-12 h-5/6 bg-CosaBlack"
          id="about"
        >
          <div
            className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left bg-CosaBlack"
            data-aos="fade-up"
          >
            <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center max-w-full max-h-full">
              <img
                alt="card img"
                className="rounded-t float-right h-auto"
                src={img}
              />
            </div>
            <div
              className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8 bg-CosaBlack"
              data-aos="zoom-in"
              data-aos-delay="500"
            >
              <h3 className="text-3xl  text-primary font-bold">COSA</h3>
              <div>
                <p className="my-3 text-xl text-primary font-semibold">
                  COSA is driven by cutting edge expertise with technology firms
                  Qamcom and Nornar.{" "}
                </p>
                <p className="my-3 text-xl text-primary font-semibold">
                  Qamcom Group is a knowledge-driven impact-accelerator for
                  well-funded technology projects to enable high ambitions,
                  speed up industrialization and an optimized route to market.{" "}
                </p>
                <p className="my-3 text-xl text-primary font-semibold">
                  Their in-kind model provides a complete business management
                  portfolio and access to a huge R&D department with
                  cutting-edge technology expertise.
                </p>
              </div>

              <div></div>
              <Link
                to="/contact"
                className="text-primary bg-CosaSiennaClay hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group"
              >
                Contact us
                <svg
                  className="w-4 h-4 ml-1 group-hover: translate-x-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Intro;
