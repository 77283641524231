import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import NavLinks from "../Navbar/NavLinks";
import cosalogo from "../../images/Cosalogo.svg";
import cosatextlogo from "../../images/cosatextlogo.svg";

const NavBar = () => {
  const [top, setTop] = useState(true);
  const [isOpen, setisOpen] = React.useState(false);
  function handleClick() {
    setisOpen(!isOpen);
  }

  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [top]);

  return (
    <section className="text-primary body-font font-publicSans">
      <nav
        className={`fixed top-0 w-full z-30 transition duration-300 ease-in-out mb-16 ${
          !top && "bg-CosaGreen shadow-lg"
        }`}
      >
        <div className="flex flex-row justify-between items-center py-2 bg-CosaGreen">
          <div className="flex flex-row justify-center md:px-12 md:mx-12 items-center text-center font-semibold">
            <Link to="/" rel="noreferrer">
              <img src={cosalogo} width={64} height={64} alt="Large logo" />
            </Link>
            <Link o="/" rel="noreferrer">
              <img
                src={cosatextlogo}
                width={159}
                height={64}
                alt="Large logo"
              />
            </Link>
            <Link to="/" rel="noreferrer">
              <h1 className="font-extrabold text-4xl text-blue-900"> </h1>
            </Link>
          </div>
          <div className="group flex flex-col items-center">
            <button
              className="p-2 rounded-lg lg:hidden text-primary"
              onClick={handleClick}
            >
              <svg
                fill="#ffffff"
                className="h-6 w-6 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                {isOpen && (
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                  />
                )}
                {!isOpen && (
                  <path
                    fillRule="evenodd"
                    d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                  />
                )}
              </svg>
            </button>
            <div className="hidden space-x-6 lg:inline-block p-5 text-primary">
              <NavLinks />
            </div>

            <div
              className={`fixed transition-transform duration-300 ease-in-out transit flex justify-center left-0 w-full h-1/30 rounded-md p-3 bg-CosaGreen rounded-lg block lg:hidden shadow-xl top-20 ${
                isOpen ? "block" : "hidden"
              } `}
            >
              <div className="flex flex-col space-y-2">
                <NavLinks />
              </div>
            </div>
          </div>
        </div>
      </nav>
    </section>
  );
};

export default NavBar;
