import React, { useState, useEffect } from "react";
import NavBar from "../components/Navbar/NavBar";
import Footer from "../components/Footer";
//import { useDocTitle } from "../components/CustomHook";
import emailjs from "emailjs-com";

const Contact = () => {
  //useDocTitle("COSA | SCAAS - Safety Case as a Service");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState([]);
  const [isEmailSent, setIsEmailSent] = useState(false);

  const sendCustomEmail = (details) => {
    // initialize using your User ID saved in the .env file
    emailjs.init("1xrd2nZjwOWlil-Rf");
    emailjs
      .send(
        "service_naeepcg", // The service ID saved in the .env file
        "template_o9wu0zn", // The template ID also saved in the .env file
        // Start of the variables defined in the template earlier
        {
          email: email,
          first_name: firstName,
          last_name: lastName,
          phone_number: phone,
          message: message,
        }
        // End of the variables defined in the template earlier
      )
      .then((response) => {
        // Debug statement on the console to show the function has been executed successfully
        console.log(response);
        setIsEmailSent(true); // Set the state to true on successful email sending
        return response;
      })
      .catch((error) => {
        // Debug statement on the console to show the error that occured
        console.log(error);
        return error;
      });
  };

  const clearErrors = () => {
    setErrors([]);
  };

  const clearInput = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setMessage("");
  };
  useEffect(() => {
    if (isEmailSent) {
      clearInput(); // Clear input fields when isEmailSent changes to true
    }
  }, [isEmailSent]);

  useEffect(() => emailjs.init("1xrd2nZjwOWlil-Rf"), []);
  console.log("Will this happen and when");
  return (
    <>
      <div>
        <NavBar />
      </div>

      <div className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24 bg-gray-200 bg-CosaBlack">
        <div
          className="container mx-auto my-8 px-4 lg:px-20 text-primary"
          data-aos="zoom-in"
        >
          <div className="w-full bg-white p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
            <div className="flex">
              <h1 className="font-bold text-center lg:text-left text-blue-900 uppercase text-4xl">
                Send us a message
              </h1>
            </div>
            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
              <div>
                <input
                  name="firstName"
                  className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline text-secondary"
                  type="text"
                  placeholder="First Name*"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  onKeyUp={clearErrors}
                />
                {errors && (
                  <p className="text-red-500 text-sm">{errors.first_name}</p>
                )}
              </div>

              <div>
                <input
                  name="lastName"
                  className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline text-secondary"
                  type="text"
                  placeholder="Last Name*"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  onKeyUp={clearErrors}
                />
                {errors && (
                  <p className="text-red-500 text-sm">{errors.last_name}</p>
                )}
              </div>

              <div>
                <input
                  name="email"
                  className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline text-secondary"
                  type="email"
                  placeholder="Email*"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onKeyUp={clearErrors}
                />
                {errors && (
                  <p className="text-red-500 text-sm">{errors.email}</p>
                )}
              </div>

              <div>
                <input
                  name="phone"
                  className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline text-secondary"
                  type="number"
                  placeholder="phone*"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  onKeyUp={clearErrors}
                />
                {errors && (
                  <p className="text-red-500 text-sm">{errors.phone_number}</p>
                )}
              </div>
            </div>
            <div className="my-4">
              <textarea
                name="message"
                placeholder="Message*"
                className="w-full h-32 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline text-secondary"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyUp={clearErrors}
              ></textarea>
              {errors && (
                <p className="text-red-500 text-sm">{errors.message}</p>
              )}
            </div>
            <div className="my-2 w-1/2 lg:w-2/4">
              <button
                type="submit"
                onClick={() => {
                  sendCustomEmail(/* pass your email details here */);
                }}
                className={`uppercase text-sm font-bold tracking-wide p-3 rounded-lg w-full 
                ${
                  isEmailSent
                    ? "bg-blue-900 text-gray-100"
                    : "bg-CosaSiennaClay text-white"
                }
                focus:outline-none focus:shadow-outline`}
              >
                {isEmailSent ? "Message Sent!" : "Send Message"}
              </button>
            </div>
          </div>
          <div className="w-full  lg:-mt-96 lg:w-2/6 px-8 py-6 ml-auto bg-blue-900 rounded-2xl">
            <div className="flex flex-col text-white">
              <div className="flex my-4 w-2/3 lg:w-3/4">
                <div className="flex flex-col">
                  <i className="fas fa-map-marker-alt pt-2 pr-2" />
                </div>
                <div className="flex flex-col">
                  <h2 className="text-2xl">Office Address</h2>
                  <p className="text-gray-400">
                    Falkenbergsgatan 3, 41285 Gothenburg
                  </p>
                </div>
              </div>

              <div className="flex my-4 w-2/3 lg:w-1/2">
                <div className="flex flex-col">
                  <i className="fas fa-phone-alt pt-2 pr-2" />
                </div>

                <div className="flex flex-col">
                  <h2 className="text-2xl">Call Us</h2>
                  <p className="text-gray-400">Tel: </p>

                  <div className="mt-5">
                    <h2 className="text-2xl">Send an E-mail</h2>
                    <p className="text-gray-400">info@cosa.dev</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
